body {
  margin: 0;
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.materialui-daterange-picker-makeStyles-dateRangeBackdrop-3 {
  z-index: 9999 !important;
  background-color: rgba(0, 0, 0, 0.5);
}

.ql-toolbar {
  display: none;
}

.ql-container {
  border: 0!important;
}